html, body {
}

#root {
}

.app-main {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.login-main {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgba(166, 168, 237, 0.4);
}

.login {
  text-align: center;
  padding: 60px 0;
  margin: 120px 0;
  width: auto;
}

.login .title {
  font-weight: bold;
  font-size: 24px;
  color: #A6A8ED;
  margin: 0 auto 10px;
}

.login .tips {
  font-size: 12px;
  color: #A6A8ED;
  margin: 0 auto 10px;
}

.login .announcement {
  font-size: 12px;
  color: #A6A8ED;
  margin: 0 auto 25px;
}

.login input {
  display: block;
  width: 300px;
  height: 35px;
  border: 1px solid #A6A8ED;
  border-radius: 5px;
  margin: 0 auto 10px;
}

.login button {
  display: block;
  width: 300px;
  height: 35px;
  border: 1px solid #A6A8ED;
  border-radius: 5px;
  margin: 20px auto 0;
  color: #ECECEC;
  font-size: 14px;
  font-weight: bold;
  background-color: #A6A8ED;
}

.workspace-main {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .workspace-l {
    width: 60px !important;
  }

  .workspace-l .avatar {
    width: 40px !important;
    height: 40px !important;
    margin: 40px auto 10px !important;
  }

  .workspace-l .avatar img {
    width: 40px !important;
    height: 40px !important;
  }

  .workspace-l .username {
    font-size: 14px !important;
  }

  .workspace-l .menu .item {
    font-size: 14px !important;
    margin: 15px auto 15px !important;
    text-align: center !important;
    padding: 5px 0 !important;
  }

  .workspace-l .menu .item .item-icon {
    display: none;
  }

  .workspace-l .menu .item .item-title {
    font-size: 10px !important;
  }

  .workspace-m {
    left: 60px !important;
    top: 60px !important;
  }

  .workspace-m .top-bar {
    left: 60px !important;
    height: 60px !important;
  }

  .workspace-m .top-bar .avatar {
    margin: 0 0 0 20px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .workspace-m .top-bar .avatar img {
    width: 40px !important;
    height: 40px !important;
  }

  .workspace-m .top-bar .username {
    height: 35px !important;
    padding: 25px 0 0 !important;
    font-size: 12px !important;
  }

  .workspace-m .top-bar .username .link {
    font-size: 12px !important;
  }

  .workspace-body .load-next {
    bottom: 100px !important;
  }

  .workspace-body .show-messages {
    left: 5% !important;
    right: 5% !important;
  }

  .message-left {
    margin-right: 15% !important;
  }

  .message-right {
    margin-left: 15% !important;
  }

  .message-left .message-avatar img {
    width: 25px !important;
    height: 25px !important;
  }

  .message-right .message-avatar img {
    width: 25px !important;
    height: 25px !important;
  }

  .send-message {
    left: 10% !important;
    width: 85% !important;
    max-width: 85% !important;
  }

  .send-message textarea {
    height: 30px !important;
    border-radius: 15px !important;
    padding: 5px 10px !important;
  }

  .send-message button {
    margin-left: 5px !important;
    max-width: 25% !important;
    width: 40px !important;
    height: 35px !important;
    border-radius: 15px !important;
  }

  .workspace-l .logout button {
    padding: 1px 5px !important;
    font-size: 12px !important;
  }
}

.workspace-l {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 240px;
  background-color: #A6A8ED;
}

.workspace-l .avatar {
  width: 90px;
  height: 90px;
  margin: 60px auto 10px;
}

.workspace-l .username {
  margin: 10px auto 80px;
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
}

.workspace-l .menu .item {
  display: block;
  text-decoration: none;
  margin: 15px auto 15px;
  font-size: 18px;
  color: #FFFFFF;
  padding: 5px 0 5px 60px;
  text-align: left;
}

.workspace-l .menu .item.active {
  background: #FFFFFF !important;
  color: #A6A8ED !important;
  font-weight: bold;
}

.workspace-l .menu .item .item-icon {
  font-size: 18px;
  color: #FFFFFF;
  margin-right: 20px;
  font-weight: bold;
}

.workspace-l .menu .item.active .item-icon {
  color: #A6A8ED !important;
}

.workspace-l .logout {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.workspace-l .logout button {
  margin: auto;
  padding: 2px 10px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  font-size: 14px;
  color: #A6A8ED;
  text-align: center;
}

.workspace-m {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 240px;
  right: 0;
  padding: 0;
  overflow: hidden;
  background-color: #F3F3F3;
}

.workspace-m .top-bar {
  position: fixed;
  top: 0;
  left: 240px;
  right: 0;
  height: 80px;
  border-bottom: 1px solid #A1A1A1;
  background-color: #FFFFFF;
}

.workspace-m .top-bar .avatar {
  float: left;
  margin: 0 0 0 30px;
  width: 60px;
  height: 60px;
  padding: 10px 0;
}

.workspace-m .top-bar .username {
  float: left;
  margin: 0 0 0 0;
  height: 45px;
  padding: 35px 0 0;
  font-weight: bold;
  font-size: 16px;
  color: #A6A8ED;
}

.workspace-m .top-bar .username .link {
  margin-left: 10px;
  text-decoration: none;
  font-size: 14px;
  color: rgba(166, 168, 237, 0.5);
  font-weight: normal;
}

.workspace-m .top-bar .username .link.active {
  color: #A6A8ED !important;
  font-weight: bold;
}

.workspace-alert {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 300px;
  height: 30px;
  margin: auto;
  background-color: #A6A8ED;
  border-radius: 15px;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 30px;
}

.workspace-body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.workspace-body .load-prev {
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .load-next {
  position: absolute;
  z-index: 10;
  bottom: 120px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .show-messages {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  bottom: 100px;
  overflow: scroll;
}

.message {
  margin-top: 10px;
  overflow: hidden;
}

.message-left {
  margin-right: 15%;
}

.message-right {
  margin-left: 15%;
}

.message-left .message-avatar {
  float: left;
  text-align: left;
  margin: 9px 10px 0 0;
}

.message-right .message-avatar {
  float: right;
  text-align: left;
  margin: 9px 0 0 10px;
}

.message-box {
  max-width: 75%;
  padding: 10px;
  border-radius: 10px;
}

.message-left .message-box {
  float: left;
  text-align: left;
  background-color: #A6A8ED;
  border: 1px solid #A6A8ED;
}

.message-right .message-box {
  float: right;
  text-align: left;
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
}

.message-left .message-box .message-time {
  font-size: 0.8em;
  color: #F0F0F0;
}

.message-right .message-box .message-time {
  font-size: 0.8em;
  color: #AAAAAA;
}

.message-left .message-box .message-text {
  line-break: anywhere;
  white-space: pre-wrap;
  color: #FFFFFF;
}

.message-right .message-box .message-text {
  line-break: anywhere;
  white-space: pre-wrap;
}

.send-message {
  position: absolute;
  bottom: 20px;
  left: 15%;
  width: 70%;
  max-width: 70%;
}

.send-message textarea {
  float: left;
  max-width: 75%;
  width: 75%;
  height: 40px;
  border: none;
  border-radius: 25px;
  padding: 5px 15px;
}

.send-message button {
  margin-left: 10px;
  max-width: 20%;
  width: 55px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #A6A8ED;
  text-align: center;
  color: #FFFFFF
}

@media screen and (max-width: 768px) {
  .workspace-body .empty {
    font-size: 14px !important;
  }

  .workspace-body .load-my-next-wishes {
    bottom: 100px !important;
  }

  .workspace-body .show-my-wishes {
    left: 5% !important;
    right: 5% !important;
  }

  .workspace-body .load-next-wishes {
    bottom: 20px !important;
  }

  .workspace-body .show-wishes {
    left: 5% !important;
    right: 5% !important;
  }

  .post-wish {
    left: 5% !important;
    width: 90% !important;
    max-width: 90% !important;
  }

  .post-wish textarea {
    height: 30px !important;
    border-radius: 15px !important;
    padding: 5px 10px !important;
  }

  .post-wish button {
    margin-left: 5px !important;
    max-width: 25% !important;
    width: 40px !important;
    height: 35px !important;
    border-radius: 15px !important;
  }
}

.workspace-body .load-my-next-wishes {
  position: absolute;
  z-index: 10;
  bottom: 100px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .show-my-wishes {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  bottom: 100px;
  overflow: scroll;
}

.workspace-body .load-next-wishes {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .show-wishes {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  bottom: 20px;
  overflow: scroll;
}

.workspace-body .empty {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  color: #AEAEAE;
}

.workspace-body .empty .link {
  text-decoration: none;
  color: #a6a8ed;
  font-weight: bold;
}

.wish {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #A6A8ED;
  border: 1px solid #A6A8ED;
  font-size: 0.8em;
  color: #FFFFFF;
  line-break: anywhere;
  white-space: pre-wrap;
  overflow: hidden;
}

.post-wish {
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
  max-width: 80%;
}

.post-wish textarea {
  float: left;
  max-width: 75%;
  width: 75%;
  height: 40px;
  border: none;
  border-radius: 25px;
  padding: 5px 15px;
}

.post-wish button {
  margin-left: 10px;
  max-width: 20%;
  width: 55px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #A6A8ED;
  text-align: center;
  color: #FFFFFF
}

@media screen and (max-width: 768px) {
  .workspace-body .load-my-next-daily {
    bottom: 160px !important;
  }

  .workspace-body .show-my-daily {
    left: 5% !important;
    right: 5% !important;
  }

  .workspace-body .load-next-daily {
    bottom: 20px !important;
  }

  .workspace-body .show-daily {
    left: 5% !important;
    right: 5% !important;
  }

  .post-daily {
    left: 5% !important;
    width: 90% !important;
    max-width: 90% !important;
  }

  .post-daily textarea {
    height: 40px !important;
    border-radius: 15px !important;
    padding: 5px 10px !important;
  }

  .post-daily button.submit {
    margin-left: 5px !important;
    max-width: 25% !important;
    width: 40px !important;
    height: 35px !important;
    border-radius: 15px !important;
  }
}

.workspace-body .load-my-next-daily {
  position: absolute;
  z-index: 10;
  bottom: 160px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .show-my-daily {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  bottom: 160px;
  overflow: scroll;
}

.workspace-body .load-next-daily {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  left: 5%;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  line-height: 40px;
  background-color: rgba(166, 168, 237, 0.4);
  border-radius: 20px;
}

.workspace-body .show-daily {
  position: absolute;
  top: 20px;
  left: 10%;
  right: 10%;
  bottom: 20px;
  overflow: scroll;
}

.daily {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #A6A8ED;
  border: 1px solid #A6A8ED;
  font-size: 0.8em;
  color: #FFFFFF;
  line-break: anywhere;
  white-space: pre-wrap;
  overflow: hidden;
}

.daily-time {
  margin-bottom: 5px;
}

.post-daily {
  position: absolute;
  bottom: 20px;
  height: 130px;
  left: 10%;
  width: 80%;
  max-width: 80%;
}

.post-daily textarea {
  position: absolute;
  left: 0;
  top: 80px;
  max-width: 75%;
  width: 75%;
  height: 40px;
  border: none;
  border-radius: 15px;
  padding: 5px 10px;
}

.post-daily button.submit {
  position: absolute;
  bottom: 0;
  left: 80%;
  max-width: 20%;
  width: 51px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #A6A8ED;
  text-align: center;
  color: #FFFFFF
}

.post-daily input.upload-images {
  display: none;
}

.post-daily label.upload-images {
  position: absolute;
  top: 15px;
  left: 5px;
  color: #A6A8ED;
  font-weight: bold;
  font-size: 14px;
}

.post-daily .images {
  position: absolute;
  left: 80px;
  top: 5px;
  width: 80%;
  max-width: 80%;
}

.post-daily .images .image {
  float: left;
  margin: 0 10px;
  height: 40px;
  overflow: hidden;
}

.post-daily .images .image img {
  width: 40px;
}

.show-my-daily .images {
  overflow: hidden;
  margin-bottom: 5px;
}

.show-my-daily .images .image {
  float: left;
  margin: 0 10px 0 0;
}

.show-daily .images {
  overflow: hidden;
  margin-bottom: 5px;
}

.show-daily .images .image {
  float: left;
  margin: 0 10px 0 0;
}

.show-img {
  position: absolute;
  background: rgba(127, 127, 127, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.show-img img {
  position: absolute;
  top: 10%;
  max-width: 80%;
  max-height: 80%;
  align-self: center;
}

.show-my-daily .audio {
  margin-bottom: 5px;
}

.show-daily .audio {
  margin-bottom: 5px;
}

.show-my-daily audio {
  width: 250px;
  height: 30px;
}

.show-daily audio {
  width: 250px;
  height: 30px;
}

.post-daily .record {
  position: absolute;
  top: 40px;
  left: 0;
  color: #A6A8ED;
  font-weight: bold;
  font-size: 14px;
}

.post-daily .record audio {
  position: absolute;
  top: 0px;
  left: 80px;
  width: 250px;
  height: 30px;
}

.post-daily .record button.toggle {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 70px;
  border: none;
  background-color: #A6A8ED;
  text-align: center;
  color: #FFFFFF;
}
